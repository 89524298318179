export default () => ({
    selectedTechnique: null,

    onClick(event) {
        if (event.target.value === this.selectedTechnique) {
            this.selectedTechnique = null
        }
        this.replaceHash(event.target.value);
    },

    showArtistsByTechnique(technique) {
        const artists = [...this.$refs.list.children]

        artists.forEach((artist) => {
            const techniques = artist.dataset.techniques.split(',')

            artist.classList.toggle(
                'hidden',
                technique && !techniques.includes(technique)
            )
        })
    },

    activateLink(technique) {
        setTimeout(function() {
            document.getElementById(technique).checked = true;
        },0)
    },

    replaceHash(hash) {
        return window.location.replace(
            '#' + hash.replace(/^#/, '')
        );
    },

    init() {
        this.$watch('selectedTechnique', this.showArtistsByTechnique.bind(this))
        if(window.location.hash) {
            let h = window.location.hash.substring(1)
            this.showArtistsByTechnique(h)
            this.activateLink(h)
        }
    },
})
